// ./pages/Home/Home.js
import React from 'react';
import Introduction from '../../components/Home/Introduction';
import Techstack from '../../components/Home/Techstack';
import './Home.scss'

const Home = () => {
    return (
        <div className="home-container">
            <div className="introduction">
                <Introduction/>
            </div>
            <div className="tech-stack">
                <Techstack/>
            </div>
        </div>
    );
};

export default Home;
