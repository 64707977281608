// ./components/Home/Introduction.js
import React from 'react';
import GitHubButton from 'react-github-btn';
import './Introduction.scss';

const Introduction = () => {
    return (
        <div className="introduction-container">
            <div className="left-column">
                <div className="info-row">
                    <p className="name">
                        <span className="first-name">Jon</span>
                        <span className="last-name">Russell</span>
                    </p>
                </div>
                <div className="info-row">
                    <p className="job-title">(Software Developer, UK)</p>
                </div>
                <div className="info-row">
                    <div className="about-me">
                        {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dui id ornare arcu odio ut sem nulla pharetra. Consequat mauris nunc congue nisi vitae suscipit tellus. Faucibus nisl tincidunt eget nullam non nisi est sit amet. Tempus quam pellentesque nec nam. Sed viverra ipsum nunc aliquet. Sed adipiscing diam donec adipiscing tristique. Tincidunt ornare massa eget egestas purus viverra. Rhoncus est pellentesque elit ullamcorper dignissim cras tincidunt. A scelerisque purus semper eget duis at tellus at.</p>*/}
                        {/*<p>Etiam dignissim diam quis enim lobortis scelerisque fermentum dui faucibus. Aliquam ut porttitor leo a diam sollicitudin tempor id. Amet consectetur adipiscing elit duis tristique. Integer vitae justo eget magna fermentum iaculis eu non diam. Molestie a iaculis at erat pellentesque adipiscing commodo elit.</p>*/}
                        <p>
                            Hello, I'm a passionate backend developer based in the Liverpool, having graduated with First-Class Honors in Software Engineering in 2018. With several years of experience, 
                            I currently work as a Software Engineering Consultant.
                            Tackling tasks with enthusiasm, challenges are met head-on, whether it involves mastering new design patterns, restructuring code or working with various programming languages. 
                            I thrive on learning and implementing new frameworks and designs, and I find satisfaction in revitalizing legacy software through design patterns and refactoring. 
                            In essence, I'm a versatile developer, treating programming languages as tools to achieve project goals.
                        </p>
                        <p>
                            Beyond coding, I cherish spending quality time with my wonderful family. My two sons and I
                            share enjoyable moments playing games such as Minecraft and Roblox.
                            In addition to my professional pursuits, I find joy in tinkering with Raspberry Pi and
                            exploring the possibilities of Home Assistant.
                        </p>
                    </div>
                </div>
            </div>
            <div className="right-column">
                <img className="profile-image" src="/img/myself2.jpg" alt="Profile"/>
                <div className="info-row">
                    <div data-tooltip="Jonopoly@GitHub" data-position="bottom center" style={{marginTop: 10}}>
                        <GitHubButton href="https://github.com/Jonopoly/AnonyPy" data-show-count="true"
                                      aria-label="Star Jonopoly/AnonyPy on GitHub">AnonyPy</GitHubButton>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Introduction;
