// ./App.js
import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css';
import Home from './pages/Home/Home';
import Contact from './pages/Contact/Contact';
import Navbar from "./components/Navbar/Navbar";


function App() {
    return (
        <Router>
            <div className="App">
                <Navbar/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    {/* Admin route without appearing in the navigation bar */}
                </Routes>

            </div>

        </Router>
    );
}

export default App;
