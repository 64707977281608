// frontend/src/components/Contact.js
import React, {useEffect, useState} from 'react';
import './Contact.scss';

const Contact = () => {
    const [emailToken, setEmailToken] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        EmailFrom: '',
        EmailBody: ''
    });
    const [showForm, setShowForm] = useState(true);
    const [responseValue, setResponseValue] = useState('');

    useEffect(() => {
        fetch('https://localhost:7022/Email')
            .then(response => response.json())
            .then(data => {
                setEmailToken(data);
            })
            .catch(error => {
                console.error('Error fetching email token:', error);
            });
    }, []);


    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        try {
            setLoading(true);
            e.preventDefault();
            const response = await fetch(`https://localhost:7022/Email/${emailToken}`, {
                method: "POST",
                body: JSON.stringify(formData),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })

            setLoading(false);

            if (response.status === 200) {
                const data = await response.json();
                setResponseValue(data.value);
                setShowForm(false);
            } else {
                console.log('Form submission failed. Status:', response.value);
            }
        } catch (error) {
            setResponseValue("You've submitted too much...");
            setShowForm(false);
        }

    };

    return (
        <div className="contact-me">
            {showForm ? (
                <form id="contact-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="email" style={{color: 'white'}}>Email Address</label>
                        <input type="text" id="email" name="EmailFrom" placeholder="Your email address..."
                               value={formData.EmailFrom} onChange={handleChange}/>
                        <label htmlFor="message" style={{color: 'white'}}>Message</label>
                        <textarea
                            id="message"
                            name="EmailBody"
                            placeholder="Leave a message..."
                            style={{height: '200px'}}
                            value={formData.EmailBody}
                            onChange={handleChange}
                        />
                    </div>
                    <button type="submit" className="button" disabled={loading}>
                        {loading ? 'Sending...' : 'Submit'}
                    </button>
                </form>
            ) : (
                <div className="contact-success">
                    <p>{responseValue}</p>
                </div>
            )}
        </div>
    );
}

export default Contact;
