// ./components/Home/Techstack.js
import React, { useEffect, useState } from 'react';
import './Techstack.scss';

const Techstack = () => {
    const [skillsData, setSkillsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const cachedSkillsData = JSON.parse(localStorage.getItem('cachedSkillsData'));

            if (cachedSkillsData) {
                setIsLoading(false);
                setSkillsData(cachedSkillsData);
            } else {
                try {
                    const response = await fetch('https://api.jonrussell.co.uk/Techstack');
                    const data = await response.json();

                    setSkillsData(data);
                    localStorage.setItem('cachedSkillsData', JSON.stringify(data));
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 2345);
                } catch (error) {
                    console.error('Error fetching data:', error);
                    setIsLoading(false);
                }
            }
        };

        fetchData();
    }, []);

    return (
        <div className="tech-stack-container">
            {isLoading ? (
                <div className="loading-message">Fetching Techstack...</div>
            ) : (
                skillsData.map(({ id, name, icon }) => (
                    <div key={id} className="skill-icon" title={name}>
                        <img src={`/img/${icon}.png`} alt={name} />
                    </div>
                ))
            )}
        </div>
    );
};

export default Techstack;
