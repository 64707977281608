// ./components/Contact/Contact.js
import React, { useEffect, useState } from 'react';
import './Contact.scss';

const Contact = ({ onClose }) => {
    const [emailToken, setEmailToken] = useState([]);
    const [emailSubmitted, setEmailSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        EmailFrom: '',
        EmailBody: ''
    });
    const [responseValue, setResponseValue] = useState('');

    useEffect(() => {
        fetch('https://api.jonrussell.co.uk/Email')
            .then(response => response.json())
            .then(data => {
                setEmailToken(data);
            })
            .catch(error => {
                console.error('Error fetching email token:', error);
            });
    }, []);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleCancel = () => {
        onClose(); // Add this line to ensure that setShowContact is also updated
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`https://api.jonrussell.co.uk/Email/${emailToken}`, {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            });
            if (response.status === 200) {
                const data = await response.json();
                setResponseValue(data.value);
                setEmailSubmitted(true);
                setTimeout(() => {
                    onClose(); // Call the onClose callback passed from Navbar
                }, 1000);
            } else {
                console.log('Form submission failed. Status:', response.status);
            }
        } catch (error) {
            setResponseValue("You've submitted too much...");
            onClose(); // Call the onClose callback passed from Navbar
        }
    };

    return (
        <div className="contact-overlay">
                {emailSubmitted ? (
                    <div className="contact-success">
                        <p>Email Sent.</p>
                    </div>
                ) : (
                    <form id="contact-form" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="email" style={{color: 'white'}}>Email Address</label>
                            <input type="text" id="email" name="EmailFrom" placeholder="Your email address..."
                                   value={formData.EmailFrom} onChange={handleChange}/>
                            <label htmlFor="message" style={{color: 'white'}}>Message</label>
                            <textarea
                                id="message"
                                name="EmailBody"
                                placeholder="Leave a message..."
                                style={{height: '200px'}}
                                value={formData.EmailBody}
                                onChange={handleChange}
                            />
                        </div>
                        <button type="submit" className="button">Submit</button>
                        <button type="button" className="button" onClick={handleCancel}>Cancel</button>
                    </form>
                )}

        </div>
    );
};

export default Contact;
