// ./components/Navbar/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.scss';
import Contact from '../Contact/Contact'; // Import the Contact component

const Navbar = () => {
    const [showContact, setShowContact] = useState(false);


    const toggleContact = () => {
        setShowContact(!showContact);
    };



    return (
        <nav>
            <div className="left">
                <ul>
                    <li><Link to="/">Home</Link></li>
                </ul>
            </div>
            <div className="right">
                <ul>
                    <button type="button" onClick={toggleContact} className="link-button">Contact Me </button>
                    <li><a href="https://cv.jonrussell.co.uk" target="_blank" rel="noopener noreferrer">CV</a></li>
                </ul>
            </div>

            {/* Display the Contact component conditionally */}
            {showContact && <Contact onClose={toggleContact}/>}
        </nav>
    );
};

export default Navbar;
